import { useEffect, useState } from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

// @ts-ignore
import * as logo from '../../../assets/images/logo.png';
import { useTypedSelector } from '../../../reducers';
import { IntlMessages } from '../../../util';
import { useNavigate } from 'react-router-dom';
import { renderTFFSelectInput, renderTFFTextInput } from '../Fields/TUILightThemeFields';
import TFFAlert from '../../../styled-components/TFFAlert';
import TFFButton from '../../../styled-components/TFFButton';

export interface ISearchOrderFormData {
  idType?: 'bookingID' | 'clientPnr' | 'tfmPnr' | 'midocoID' | 'AF' | 'KL' | 'EK' | 'NSK' | 'SQ' | 'NDCX' | 'AMADEUS';
  crsBookingId?: string;
  forename?: null | string;
  surname?: null | string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: '40px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '15pt',
      padding: 15,
    },
    spacer: {
      height: 20,
    },
    formWrapper: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '80%',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    btnWrapper: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '80%',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    info: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '80%',
      },
      border: '0px solid black',
      paddingLeft: 0,
    },
  }),
);

const SearchOrderForm = (props: InjectedFormProps<ISearchOrderFormData>) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  const { searchOrderForm } = useTypedSelector(({ form }) => form);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disableId, setDisableID] = useState<boolean>(false);
  const navigate = useNavigate();

  const intl = useIntl();

  const resetForm = () => {
    reset();
    navigate(`/tff/order/search`);
  };

  const classes = useStyles();

  const formValues: ISearchOrderFormData = searchOrderForm?.values;

  useEffect(() => {
    setDisabled(!!formValues?.crsBookingId);
    // const disableID = formValues?.forename || formValues?.surname || formValues?.city ? true : false;
    const disableID = !!(formValues?.forename || formValues?.surname);
    setDisableID(disableID);
  }, [formValues]);

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <div className={classes.paper}>
        <Link className="logo-lg" style={{ width: 90, height: 40 }} to="/" title="Flight Order Fulfillment">
          <img src={logo.default} width={'100%'} alt="Flight Order Fulfillment" title="Flight Order Fulfillment" />
        </Link>
        <Typography variant="h5" color="primary" style={{ margin: '20px 0px', fontWeight: 700 }}>
          TUI Flight Fulfillment
        </Typography>
        <div className={classes.formWrapper}>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="idType"
                inputId="idType"
                labelText="ID Type"
                component={renderTFFSelectInput}
                disabled={disableId}
              >
                <option value={'bookingID'}>
                  <IntlMessages id={'pages.orderSearchPage.label.recordLocator'} />
                </option>
                <option value="midocoID">
                  <IntlMessages id={'pages.orderSearchPage.label.midocoid'} />
                </option>
                <option value="NSK">NSK</option>
                <option value="AF">Air France</option>
                <option value="KL">KLM</option>
                <option value="EK">Emirates</option>
                <option value="SQ">Singapore Airlines</option>
                <option value="NDCX">NDCX</option>
                <option value="AMADEUS">AMADEUS</option>
                <option value="clientPnr">AtComRes-BookingId</option>
                <option value="tfmPnr">TFM-Recordlocator</option>
              </Field>
            </div>
            <div className="col-md-6">
              <Field
                name="crsBookingId"
                inputId="tff-crsBookingId"
                labelText="Id"
                component={renderTFFTextInput}
                isBookingId
                disabled={disableId}
              />
            </div>
          </div>
          <div className={classes.spacer} />
          <Field
            name="surname"
            inputId="surname"
            labelText={intl.formatMessage({ id: 'pages.orderSearchPage.label.lastname' })}
            component={renderTFFTextInput}
            disabled={disabled}
          />
          <div className={classes.spacer} />
          <Field
            name="forename"
            inputId="forename"
            labelText={intl.formatMessage({ id: 'pages.orderSearchPage.label.firstname' })}
            component={renderTFFTextInput}
            disabled={!formValues?.surname}
          />
          <div className={classes.spacer} />

          <TFFAlert variant={'info'} text={intl.formatMessage({ id: 'pages.orderSearchPage.hint' })} />
        </div>

        <div className={classes.spacer} />

        <div className={classes.btnWrapper}>
          <TFFButton
            buttonText={intl.formatMessage({ id: 'pages.orderSearchPage.button.reset' })}
            handleClick={resetForm}
            disabled={pristine || submitting}
            outline
          />
          <TFFButton
            buttonText={intl.formatMessage({ id: 'pages.orderSearchPage.button.search' })}
            disabled={pristine || submitting}
            type="submit"
          />
        </div>
      </div>
    </Form>
  );
};

const validate = (SearchOrderFormData: ISearchOrderFormData) => {
  const errors: any = {};
  if (
    SearchOrderFormData.idType === 'midocoID' &&
    SearchOrderFormData.crsBookingId &&
    isNaN(+SearchOrderFormData.crsBookingId)
  ) {
    errors.id = 'The OrderId must contains only numbers';
  }

  return errors;
};

export default reduxForm<ISearchOrderFormData>({
  form: 'searchOrderForm',
  validate,
  enableReinitialize: false,
  initialValues: {
    idType: 'bookingID',
  },
})(SearchOrderForm);
